// Load the speculation rules if the browser supports it. Use a loader script since using a header makes us unable to
// properly cache and use etags for revalidation.

import specRules from "../speculation-rules/rules.json";

if (HTMLScriptElement.supports && HTMLScriptElement.supports("speculationrules")) {
  const specScript = document.createElement("script");
  specScript.type = "speculationrules";
  specScript.textContent = JSON.stringify(specRules);
  document.body.appendChild(specScript);
}
